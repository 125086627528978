.foot {
    background: #000000;
    color: #ffffff;
    padding: 1em 1em 1.5em;
    text-align: center;
    .foot-logo {
        height: 7em;
        padding: 0.2em 0 0.3em;
    }
    .foot-copyright {
        @include futura-pt-book();
        font-size: 0.7em;
    }
    .foot-title {
        color: var(--main-gold);
        @include futura-pt-light();
        margin-top: 1.5em;
    }
    .foot-menu li {
        display: inline-block;
        padding-left: 0.5em;
        padding-right: 0.5em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
    }
    .foot-menu a,
    .foot-contact,
    .foot-contact a,
    .foot-address {
        @include futura-pt-light();
        text-decoration: none;
        color: var(--main-lightgray);
    }
    .foot-menu a:hover,
    .foot-menu a:focus,
    .foot-contact a:hover,
    .foot-contact a:focus {
        @include futura-pt-light();
        text-decoration: underline;
        color: var(--main-lightgray);
    }
    /* 
    Full Desktop Sized
    */
    @media (min-width: 768px) {
        width: 100%;
        padding-left: 5vw;
        padding-right: 5vw;
        padding-top: 2em;
        padding-bottom: 2em;
        text-align: left;
        font-size: 0.8em;
        .foot-logo-section {
            display: inline-block;
            width: 20vw;
            margin-left: 2em;
            margin-right: 2em;
        }
        .foot-logo {
            height: 10em;
            padding: 0.2em 0 0.3em;
        }
        .foot-copyright {
            @include futura-pt-light;
            margin-top: 2em;
            margin-bottom: 0.5em;
        }
        .foot-left-elements {
            float: right;
            max-width: 60vw;
            padding-right: 2em;
            display: flex;
        }
        .foot-bar,
        .foot-geninquiry,
        .foot-third {
            display: block;
            float: left;
            max-width: 25vw;
            margin-left: 2.0em;
            margin-right: 0.5em;
            margin-bottom: 2em;
        }
        .foot-title {
            font-size: 1.0em;
            margin-bottom: 1em;
            margin-top: 0.2em;
        }
        .foot-menu li {
            display: block;
            padding: 0;
        }
    }
}