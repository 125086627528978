// Imports
@import "colours";
@import "fonts";
@import "mixins";
@import "header";
@import "footer";

/* 

Main Styles

*/

* {
  @include futura-pt-book();
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16pt;
  background: var(--main-menu-bgcolor);
  color: var(--main-lightgray);
  overflow-x: hidden;
  scroll-behavior: smooth;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

// Main Page
section.home-main {
  min-height: 100vh;
  background-image: image-set(
    url("../img/GalaxyContours.png?as=webp&width=1920") type("image/webp"),
    url("../img/GalaxyContours.png") type("image/png")
  );
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 1.5em 1.5em 2em;

  .main-title {
    @include futura-pt-medium();
    display: block;
    margin-top: 20vh;
    color: #ffffff;
    font-size: 3em;
    line-height: 110%;
  }

  .main-subtitle {
    @include futura-pt-light();
    display: block;
    color: var(--main-gold);
    font-size: 1.2em;
    margin-top: 5vh;
  }
}

// Service Section
section.home-services {
  background-color: var(--main-blue);
  min-height: 100vh;
  background-image: image-set(
    url("../img/BoxBackground.png?as=webp&width=1920") type("image/webp"),
    url("../img/BoxBackground.png") type("image/png")
  );
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: auto 100%;
  padding: 1.5em 2em 1.5em;
  color: #ffffff;
  position: relative;
  z-index: 10;

  .service-option {
    padding-top: 1em;
    padding-bottom: 0.5em;
  }

  .service-icon {
    margin: 0.5em 0em 0.7em;
  }

  .service-subtitle {
    float: left;
    @include futura-pt-medium();
    font-size: 1.2em;
    margin-bottom: 0.2em;
    padding-top: 0.1em;
  }

  .service-text {
    @include futura-pt-light();
    display: inline-block;
    color: var(--main-lightgray);
    margin-top: 0.5em;
  }
}

#lightbulb {
  @include svg-icon(
    "../img/svg/lightbulb.svg",
    3em,
    3em,
    var(--main-lightblue)
  );
}

#users {
  @include svg-icon("../img/svg/users.svg", 3em, 3em, var(--main-lightblue));
}

#cogs {
  @include svg-icon("../img/svg/cogs.svg", 3em, 3em, var(--main-lightblue));
}

#hands-helping {
  @include svg-icon(
    "../img/svg/hands-helping.svg",
    3em,
    3em,
    var(--main-lightblue)
  );
}

#chalkboard-teacher {
  @include svg-icon(
    "../img/svg/chalkboard-teacher.svg",
    3em,
    3em,
    var(--main-lightblue)
  );
}

#comments {
  @include svg-icon("../img/svg/comments.svg", 3em, 3em, var(--main-lightblue));
}

// Research Section
section.home-research {
  position: relative;
  z-index: 10;
  background-color: #7f5643;
  min-height: 100vh;
  color: #ffffff;
  padding: 1.5em 2em 1.5em;

  .section-research-image {
    margin-left: -2em;
    margin-top: -1.5em;
    width: 100vw;
    min-height: 40vw;
    background-image: image-set(
      url("../img/Pendulum.jpg?as=webp&width=960") type("image/webp"),
      url("../img/Pendulum.jpg") type("image/jpg")
    );
    background-repeat: no-repeat;
    background-size: cover;
  }

  .section-title {
    padding-top: 0.5em;
  }

  .section-text {
    @include futura-pt-light();
    color: var(--main-gold);
    font-size: 1.2em;
  }
}

.section-link {
  padding-top: 1.5em;
  padding-bottom: 2em;

  a {
    @include futura-pt-light();
    color: #ffffff;
    font-size: 1.2em;
    text-decoration: underline;
  }
}

.section-title {
  padding-top: 1em;
  padding-bottom: 0.5em;
  font-size: 2em;
  line-height: 120%;
}

/* 

    Full Desktop Sized

*/

@media (min-width: 768px) {
  section {
    width: 100%;
  }

  section.home-main {
    .home-holder {
      position: absolute;
      top: 12vh;
      left: 5em;
      z-index: 10;
    }

    .main-title {
      width: 70vw;
      max-width: 70%;
      font-size: 3.4em;
    }

    .main-subtitle {
      width: 50vw;
      max-width: 70%;
      font-size: 1.2em;
      margin-top: 1em;
    }
  }

  section.home-services {
    padding-left: 10vw;
    padding-right: 10vw;
    text-align: justify;

    .section-title {
      @include futura-pt-medium;
      margin-top: 10vh;
      text-align: left;
    }

    .service-option-holder {
      width: 100%;
    }

    .service-option {
      display: inline-block;
      width: 28%;
      text-align: left;
      padding-left: 2em;
      height: 8em;
      vertical-align: top;
    }

    .service-icon {
      float: left;
      margin-left: -2.7em;
      // padding: 0 0.5em 0;
    }

    .service-right {
      margin-left: 0.5em;
      width: 100%;
      float: left;
    }

    .service-subtitle {
      font-size: 0.9em;
    }

    .service-text {
      @include futura-pt-light;
      font-size: 0.8em;
      margin-top: 0;
    }

    .stretch {
      width: 100%;
      display: inline-block;
      font-size: 0;
      line-height: 0;
    }

    #lightbulb {
      @include svg-icon(
        "../img/svg/lightbulb.svg",
        2.5em,
        2.5em,
        var(--main-lightblue)
      );
    }

    #users {
      @include svg-icon(
        "../img/svg/users.svg",
        2.5em,
        2.5em,
        var(--main-lightblue)
      );
    }

    #cogs {
      @include svg-icon(
        "../img/svg/cogs.svg",
        2.5em,
        2.5em,
        var(--main-lightblue)
      );
    }

    #hands-helping {
      @include svg-icon(
        "../img/svg/hands-helping.svg",
        2.5em,
        2.5em,
        var(--main-lightblue)
      );
    }

    #chalkboard-teacher {
      @include svg-icon(
        "../img/svg/chalkboard-teacher.svg",
        2.5em,
        2.5em,
        var(--main-lightblue)
      );
    }

    #comments {
      @include svg-icon(
        "../img/svg/comments.svg",
        2.5em,
        2.5em,
        var(--main-lightblue)
      );
    }
  }

  section.home-research {
    display: flex;

    .section-research-image {
      margin: auto -2em;
      width: 50vw;
      height: 20em;
      background-position-x: right;
      min-height: unset;
    }

    .home-research-right {
      width: 30vw;
      margin: auto;
      font-size: 0.9em;

      .section-title {
        @include futura-pt-medium;
        font-size: 2em;
      }

      .section-link {
        text-align: left;
      }
    }
  }

  div.section-link {
    text-align: right;
  }

  .desktop-limit {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    position: relative;
  }
}

/* 

Contact Us Page

*/

.contact-main {
  display: flex;
  min-height: 90vh;
  background-image: image-set(
    url("../img/MainDuotone.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/MainDuotone.jpg") type("image/jpg")
  );
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 5em 1.5em 5em;
  color: #ffffff;

  .contact-left {
    margin: auto;
    padding: 0.7em 0 0.7em;

    .contact-title {
      @include futura-pt-bold;
      font-size: 3em;
      line-height: 1em;
      padding: 0.5em 0 0.2em;
    }

    .contact-text {
      @include futura-pt-light;
      font-size: 1.2em;
      padding: 0.5em 0 0.5em;
    }
  }

  .contact-right {
    margin: auto;
    padding: 0.7em 0 0.7em;

    bolder {
      @include futura-pt-medium;
    }

    .contact-companyname {
      @include futura-pt-medium;
      font-size: 1.3em;
      padding-bottom: 0.2em;
    }

    .contact-email {
      @include futura-pt-light;
      padding-bottom: 1em;

      a {
        text-decoration: none;
        color: #ffffff;
      }
    }

    .contact-mailing {
      @include futura-pt-light;
      padding-bottom: 1.6em;
    }

    .contact-socials {
      display: flex;
      flex-direction: row;
    }

    .social-link {
      margin-right: 1.5em;
    }

    a:hover,
    a:focus {
      text-decoration: underline;
      color: var(--main-gold);

      #linkedin,
      #twitter,
      #facebook,
      #github {
        background-color: var(--main-gold);
      }
    }
  }

  #linkedin {
    @include svg-icon("../img/svg/linkedin.svg", 2em, 2em, #ffffff);
  }

  #twitter {
    @include svg-icon("../img/svg/twitter.svg", 2em, 2em, #ffffff);
  }

  #facebook {
    @include svg-icon("../img/svg/facebook.svg", 2em, 2em, #ffffff);
  }

  #github {
    @include svg-icon("../img/svg/github.svg", 2em, 2em, #ffffff);
  }

  /* 

    Full Desktop Version 

    */
  @media (min-width: 768px) {
    padding-left: 10vw;
    padding-right: 10vw;

    .desktop-limit {
      display: flex;
    }

    .contact-left,
    .contact-right {
      max-width: 35vw;
      margin: auto 2em;
    }

    .contact-left {
      .contact-title {
        font-size: 3.5em;
      }
    }

    .contact-right {
      .contact-companyname {
        margin-top: 2em;
      }
    }
  }
}

/* 

404 Page

*/

.e404-main {
  display: flex;
  min-height: 90vh;
  background-color: var(--main-blue);
  background-image: image-set(
    url("../img/BoxBackground.png?as=webp&width=1920") type("image/webp"),
    url("../img/BoxBackground.png") type("image/png")
  );
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 5em 1.5em 5em;
  color: #ffffff;

  .e404-left {
    margin: auto;
    padding: 0.7em 0 0.7em;

    .e404-title {
      @include futura-pt-bold;
      text-align: center;
      font-size: 5em;
      line-height: 1em;
      padding: 0.4em 0 0.1em;

      .text-404 {
        @include futura-pt-bold;
        font-size: 1.3em;
      }
    }
  }

  .e404-right {
    margin: auto;
    padding: 0.7em 0 0.7em;

    .e404-text {
      @include futura-pt-medium;
      font-size: 1.4em;
      padding: 0.5em 0 3em;
      text-align: center;

      .highlight {
        @include futura-pt-medium;
        color: var(--main-gold);
      }
    }

    .e404-links {
      display: flex;
      flex-direction: row;
    }

    a.linkbutton {
      margin: auto;
      text-decoration: none;
      color: #ffffff;
      background-color: var(--main-mediumblue);
      padding: 0.5em 1em;
      font-size: 1.5em;
      border-radius: 1.5em;
    }

    a:hover,
    a:focus {
      text-decoration: none;
      background-color: var(--main-orange);
      transition: background 500ms ease;
    }
  }

  /* 

    Full Desktop Version 

    */
  @media (min-width: 768px) {
    padding-left: 5vw;
    padding-right: 5vw;

    .desktop-limit {
      display: flex;
    }

    .e404-left,
    .e404-right {
      margin: auto 2em;
    }

    .e404-left {
      max-width: 30vw;

      .e404-title {
        font-size: 6em;
        line-height: 0.8em;
      }
    }

    .e404-right {
      .e404-text {
        margin-top: 2em;
        font-size: 1.75em;
        text-align: left;
        padding-bottom: 1.2em;
      }

      .e404-links {
        justify-content: center;
      }

      a.linkbutton {
        font-size: 1.2em;
        padding: 0.3em 1em;
        margin: auto 2em;
      }
    }
  }
}

/* 

WaW Page

*/

@mixin waw-backgrounds {
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 5em 1.5em 5em;
}

.waw-head {
  @include waw-backgrounds();
  min-height: 100vh;
  background-color: var(--main-rose);
  background-image: image-set(
    url("../img/Trees.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/Trees.jpg") type("image/jpg")
  );
  color: #ffffff;

  .desktop-limit {
    display: flex;
  }

  .waw-top-text {
    @include futura-pt-medium;
    font-size: 1.5em;
    margin: auto;
    padding: 5vh 5vw;
    background-color: rgba(150, 101, 83, 0.5);
  }
}

.waw-story {
  @include waw-backgrounds();
  min-height: 90vh;
  background-color: var(--main-rose);

  .waw-story-title {
    @include futura-pt-bold;
    color: var(--main-gold);
    font-size: 1.7em;
    padding-bottom: 0.5em;
  }

  p {
    margin-bottom: 1em;
  }

  .waw-story-pullquote {
    font-size: 1.7em;
    text-align: center;
    margin: 2em auto 0em;
    padding-top: 1.2em;
    padding-bottom: 1.2em;
    border-top: 5px solid var(--main-gold);
    border-bottom: 5px solid var(--main-gold);
  }
}

.waw-founder {
  @include waw-backgrounds();
  min-height: 90vh;
  background-color: var(--main-blue);
  background-image: image-set(
    url("../img/BlueFibers.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/BlueFibers.jpg") type("image/jpg")
  );

  .waw-founder-heading {
    @include futura-pt-medium;
    font-size: 1.2em;
  }

  .waw-founder-name {
    @include futura-pt-bold;
    font-size: 1.7em;
  }

  .waw-founder-title {
    @include futura-pt-light;
    font-size: 1.2em;
    margin-bottom: 1em;
  }

  p {
    margin-bottom: 1em;
  }

  .waw-founder-socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1em;
  }

  .social-icons {
    margin-right: 1.5em;
  }

  .waw-founder-image {
    width: 60vw;
    height: 60vw;
    border-radius: 50%;
    margin: 0 auto 1em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: image-set(
      url("../img/New_Mubdi.jpg?as=webp&width=1920") type("image/webp"),
      url("../img/New_Mubdi.jpg") type("image/jpg")
    );
  }

  a:hover,
  a:focus {
    text-decoration: underline;
    color: var(--main-gold);

    #linkedin,
    #twitter,
    #web,
    #github {
      background-color: var(--main-gold);
    }
  }

  #linkedin {
    @include svg-icon("../img/svg/linkedin.svg", 2em, 2em, #ffffff);
  }

  #twitter {
    @include svg-icon("../img/svg/twitter.svg", 2em, 2em, #ffffff);
  }

  #web {
    @include svg-icon("../img/svg/globe.svg", 2em, 2em, #ffffff);
  }

  #github {
    @include svg-icon("../img/svg/github.svg", 2em, 2em, #ffffff);
  }
}

.waw-team {
  @include waw-backgrounds();
  background-color: var(--main-gray);
  background-image: image-set(
    url("../img/WavyLines.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/WavyLines.jpg") type("image/jpg")
  );
  min-height: 90vh;

  .waw-team-title {
    @include futura-pt-bold;
    color: var(--main-lightgray);
    text-align: center;
    font-size: 1.7em;
    padding-bottom: 0.5em;
  }

  .team-holder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
  }

  .team-member-holder {
    position: relative;
    width: 33%;
    min-width: 15em;
    height: 15em;
  }

  .team-member-hover {
    @include futura-pt-light;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(0.5, 0.5, 0.5, 0.6);
    backdrop-filter: grayscale(100%) blur(5px);
    padding: 1em 1.5em 5em;
    opacity: 0;
    transition: opacity 0.2s;
    font-size: 0.9em;

    .qualifications {
      text-transform: uppercase;
      font-size: 0.7em;
      color: var(--main-gold);
      margin-bottom: 1em;
    }
  }

  .team-member-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;

    display: flex;
    flex-direction: column;
    padding: 0.5em 1em 1em;
    color: #ffffff;

    background-color: var(--main-gray);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .team-member-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 15;
    display: flex;
    flex-direction: column;
    padding: 0.5em 1em 1em;
    color: #ffffff;

    .team-member-position {
      @include futura-pt-book;
      margin-top: auto;
      color: var(--main-gold);
      text-transform: uppercase;
      font-size: 0.7em;
    }

    .team-member-name {
      @include futura-pt-bold;
    }
  }

  .team-member-info:hover ~ .team-member-hover {
    opacity: 1;
  }

  // Team Photos

  div#team-mubdi {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0.7)
      ),
      image-set(
        url("../img/team/mubdi.jpg?as=webp&width=1920") type("image/webp"),
        url("../img/team/mubdi.jpg") type("image/jpg")
      );
  }

  div#team-jen {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0.7)
      ),
      image-set(
        url("../img/team/jen.jpg?as=webp&width=1920") type("image/webp"),
        url("../img/team/jen.jpg") type("image/jpg")
      );
  }

  div#team-hansen {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0.7)
      ),
      image-set(
        url("../img/team/hansen.jpg?as=webp&width=1920") type("image/webp"),
        url("../img/team/hansen.jpg") type("image/jpg")
      );
  }

  div#team-tai {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0.7)
      ),
      image-set(
        url("../img/team/tai.jpg?as=webp&width=1920") type("image/webp"),
        url("../img/team/tai.jpg") type("image/jpg")
      );
  }

}

.waw-opportunities {
  @include waw-backgrounds();
  background-color: var(--main-blue);
  background-image: image-set(
    url("../img/BlueArcs.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/BlueArcs.jpg") type("image/jpg")
  );
  min-height: 90vh;
  color: #ffffff;

  .waw-opportunities-pretitle {
    @include futura-pt-bold;
    text-transform: uppercase;
    font-size: 0.7em;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }

  .waw-opportunities-title {
    @include futura-pt-bold;
    font-size: 1.6em;
    margin-bottom: 0.75em;
    line-height: 1.1;
  }

  p {
    margin-top: 1em;
  }

  .waw-opportunities-button-container {
    display: flex;
  }

  .waw-opportunities-button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    margin-bottom: 2em;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    color: #ffffff;
    background-color: var(--main-rose);
    padding: 0.75em;
    transition: background 500ms ease;
  }

  .waw-opportunities-button:hover {
    background-color: var(--main-menu-bgcolor);
  }
}

.waw-name {
  @include waw-backgrounds();
  background-color: var(--main-rose);
  background-image: image-set(
    url("../img/MainDuotone.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/MainDuotone.jpg") type("image/jpg")
  );

  .waw-name-title {
    @include futura-pt-bold;
    font-size: 1.7em;
    margin-bottom: 0.5em;

    i {
      @include futura-pt-bold-oblique;
    }
  }

  i {
    @include futura-pt-book-oblique;
  }

  p {
    margin-bottom: 1em;
  }

  .waw-name-picture {
    width: 75vw;
    min-height: 40vh;
    margin: auto;
    background-image: image-set(
      url("../img/taqi-al-din.jpg?as=webp&width=1920") type("image/webp"),
      url("../img/taqi-al-din.jpg") type("image/jpg")
    );
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media (min-width: 768px) {
  .waw-head,
  .waw-story,
  .waw-founder,
  .waw-team,
  .waw-opportunities,
  .waw-name {
    padding-left: 3vw;
    padding-right: 3vw;

    .desktop-limit {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }

    .waw-top-text {
      @include futura-pt-book();
      font-size: 2em;
      line-height: 1.25em;
      margin: auto;
      max-width: 20em;
      padding: 1.5em 1em;
    }

    .waw-story-text {
      max-width: 30em;
      padding: 3em 3em 1em 3em;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .waw-story-title {
        font-size: 2.5em;
      }
    }

    .waw-story-pullquote {
      max-width: 10em;
      margin: auto;
      padding-top: 0.7em;
      padding-bottom: 0.7em;
      border-top-width: 2px;
      border-bottom-width: 2px;
      font-size: 1.7em;
    }

    .waw-founder-image {
      width: 35vw;
      height: 35vw;
      max-width: 25em;
      max-height: 25em;
      margin: 0em 1em auto -5em;
      position: relative;
      overflow: hidden;
    }

    .waw-founder-text {
      width: 45vw;
      max-width: 50%;
      padding-left: 2em;
      font-size: 0.9em;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .waw-founder-heading {
        font-size: 1.5em;
        margin-bottom: -0.2em;
      }

      .waw-founder-name {
        font-size: 2.5em;
        color: #ffffff;
      }
    }

    .waw-team-text {
      padding: 3em 3em 1em 3em;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .waw-team-title {
        font-size: 2.5em;
      }
    }

    .waw-opportunities-text {
      display: flex;
      align-items: center;
    }

    .waw-opportunities-pretitle {
      font-size: 1.2em;
      margin-bottom: 0.2em;
    }

    .waw-opportunities-title {
      font-size: 4em;
      margin-bottom: 0.75em;
      line-height: 1.1;
    }

    .waw-opportunities-body {
      max-width: 50vw;
      margin-left: auto;
      margin-right: auto;
    }

    .waw-name-description {
      max-width: 25em;
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .waw-name-title {
        font-size: 2em;
        line-height: 1.1em;
      }

      .waw-name-body {
        font-size: 0.9em;
      }
    }

    .waw-name-picture {
      max-width: 40%;
      width: 35vw;
      margin-left: auto;
      margin-right: auto;
      margin-top: -5em;
      margin-bottom: -5em;
    }
  }
}

/* 

Research Page

*/

@mixin research-backgrounds {
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 5em 1.5em 5em;
}

.research-head {
  @include research-backgrounds();
  min-height: 100vh;
  background-color: var(--main-rose);
  background-image: image-set(
    url("../img/nightsky.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/nightsky.jpg") type("image/jpg")
  );
  color: #ffffff;

  .desktop-limit {
    display: flex;
  }

  .research-top-text {
    @include futura-pt-medium;
    font-size: 1.5em;
    margin: auto;
    padding: 5vh 5vw;
    background-color: rgba(76, 126, 151, 0.5);
  }
}

.research-image {
  width: 80vw;
  height: 80vw;
  border-radius: 50%;
  margin: 2em auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.research-description-title {
  @include futura-pt-bold;
  font-size: 2em;
  margin-bottom: 0.5em;
}

.research-stars {
  @include research-backgrounds();
  min-height: 90vh;
  background-image: image-set(
    url("../img/MainDuotone.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/MainDuotone.jpg") type("image/jpg")
  );

  .research-image {
    background-image: image-set(
      url("../img/Dragonfish_Psuedocolour_small.jpg?as=webp&width=1920")
        type("image/webp"),
      url("../img/Dragonfish_Psuedocolour_small.jpg") type("image/jpg")
    );
  }
}

.research-radiotransients {
  @include research-backgrounds();
  min-height: 90vh;
  background-image: image-set(
    url("../img/BlueArcs.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/BlueArcs.jpg") type("image/jpg")
  );

  .research-image {
    background-image: image-set(
      url("../img/ARO.jpg?as=webp&width=1920") type("image/webp"),
      url("../img/ARO.jpg") type("image/jpg")
    );
  }
}

.research-visualization {
  @include research-backgrounds();
  min-height: 90vh;
  background-image: image-set(
    url("../img/GreyDottedPattern.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/GreyDottedPattern.jpg") type("image/jpg")
  );

  .research-image {
    background-image: image-set(
      url("../img/frbvis.jpg?as=webp&width=1920") type("image/webp"),
      url("../img/frbvis.jpg") type("image/jpg")
    );
  }
}

/* 

    Full Desktop Version 

*/

@media (min-width: 768px) {
  .research-head,
  .research-stars,
  .research-radiotransients,
  .research-visualization {
    padding-left: 3vw;
    padding-right: 3vw;

    .desktop-limit {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
    }

    .research-top-text {
      margin: auto;
      max-width: 20em;
      padding: 1.5em 1em;
      @include futura-pt-book();
      font-size: 2em;
      line-height: 1.25em;
    }

    .research-text {
      max-width: 20em;
      margin: auto 1.5em;
    }

    .research-image {
      width: 35vw;
      height: 35vw;
      max-width: 20em;
      max-height: 20em;
      margin: auto;
      position: relative;
      overflow: hidden;
    }
  }
}

/*

Service Page

*/

@mixin service-backgrounds {
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 5em 1.5em 5em;
}

.service-head {
  @include service-backgrounds();
  min-height: 100vh;
  background-color: var(--main-blue);
  background-image: image-set(
    url("../img/BlueFibers.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/BlueFibers.jpg") type("image/jpg")
  );
  color: #ffffff;

  .desktop-limit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1em;
  }

  .service-head-title {
    @include futura-pt-bold;
    font-size: 2em;
    margin-bottom: 0.5em;
  }
}

.service-description {
  @include service-backgrounds();
  min-height: 100vh;

  p {
    padding-bottom: 0.5em;
  }

  dt {
    @include futura-pt-heavy();
    color: #ffffff;
    padding-top: 0.5em;
    padding-left: 0em;
    padding-right: 0em;
  }

  dd {
    padding-top: 0.25em;
    padding-left: 0em;
    padding-right: 0em;
    padding-bottom: 1.25em;
  }

  h3 {
    @include futura-pt-heavy();
    color: #ffffff;
    padding-top: 0.75em;
    padding-left: 0em;
    padding-right: 0em;
    padding-bottom: 0.25em;
  }

  a {
    text-decoration: none;
    color: #ffffff;
  }

  a:hover,
  a:focus {
    text-decoration: underline;
    color: var(--main-gold);
  }

  li {
    list-style: square inside;
  }

  ul {
    padding-bottom: 1em;
  }

  .desktop-limit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1em;
  }

  .service-description-title {
    @include futura-pt-bold;
    font-size: 1.4em;
    margin-bottom: 0.5em;
    color: #ffffff;
  }
}

section#technology-integration {
  background-color: var(--main-rose);
  background-image: image-set(
    url("../img/MainDuotone.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/MainDuotone.jpg") type("image/jpg")
  );
}

section#dataflow-management {
  background-color: var(--main-blue);
  background-image: image-set(
    url("../img/BoxBackground.png?as=webp&width=1920") type("image/webp"),
    url("../img/BoxBackground.png") type("image/png")
  );
}

section#visualization {
  background-image: image-set(
    url("../img/WavyLines.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/WavyLines.jpg") type("image/jpg")
  );
}

section#project-management {
  background-color: var(--main-blue);
  background-image: image-set(
    url("../img/BlueArcs.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/BlueArcs.jpg") type("image/jpg")
  );
}

section#training {
  background-image: image-set(
    url("../img/GreyDottedPattern.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/GreyDottedPattern.jpg") type("image/jpg")
  );
}

section#industry {
  background-color: var(--main-rose);
}

section#outreach {
  background-color: var(--main-blue);
  background-image: image-set(
    url("../img/DottedPattern.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/DottedPattern.jpg") type("image/jpg")
  );
}

/* 

    Full Desktop Version 

*/

@media (min-width: 768px) {
  .service-head,
  .service-description {
    padding-left: 5vw;
    padding-right: 5vw;

    .desktop-limit {
      display: flex;
      flex-wrap: wrap;
      justify-content: left middle;
      padding: 0 5vw 0;
    }

    .service-head-title {
      max-width: 50vw;
      line-height: 1em;
      font-size: 4em;
      margin-bottom: 0.1em;
    }

    .service-head-text {
      max-width: 70vw;
      padding: 1em 0 1em;
      font-size: 1.4em;
    }

    .service-description-title {
      max-width: 60vw;
      font-size: 2.25em;
    }

    .service-description-text {
      max-width: 70vw;
    }
  }
}

/*

Jobs Page

*/

@mixin jobs-backgrounds {
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 5em 1.5em 5em;
}

.jobs-head {
  @include jobs-backgrounds();
  min-height: 100vh;
  background-color: var(--main-blue);
  background-image: image-set(
    url("../img/BlueFibers.jpg?as=webp&width=1920") type("image/webp"),
    url("../img/BlueFibers.jpg") type("image/jpg")
  );
  color: #ffffff;

  .desktop-limit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1em;
  }

  .jobs-head-title {
    @include futura-pt-bold;
    font-size: 2em;
    margin-bottom: 0.5em;
    text-align: center;
  }

  .joblist {
    margin-left: 2em;
    margin-right: 2em;
    margin-top: 1em;
    font-size: 0.9em;
    display: flex;
    justify-content: center;
  }

  .jobentry {
    margin: 0.75em;
    text-align: center;

    .joblink {
      @include futura-pt-bold;
      color: #ffffff;
      text-decoration: underline;
      transition: color 500ms ease;
    }

    .joblink:hover,
    .joblink:focus {
      color: var(--main-orange);
    }
  }
}

.jobs-description {
  @include jobs-backgrounds();
  min-height: 100vh;
  font-size: 1em;
  line-height: 1.5;

  p {
    padding-top: 1em;
    padding-bottom: 0.5em;
  }

  b {
    @include futura-pt-bold;
  }

  h3 {
    @include futura-pt-heavy();
    color: #ffffff;
    padding-top: 1.75em;
    padding-left: 0em;
    padding-right: 0em;
    padding-bottom: 0em;
    text-align: center;
    text-transform: uppercase;
  }

  .highlight {
    font-size: 1.5em;
    color: var(--main-orange);
  }

  hr {
    margin-top: 2.5em;
    margin-bottom: 2.5em;
    border-color: var(--main-lightgray);
  }

  a {
    text-decoration: none;
    color: var(--main-orange);
  }

  a:hover,
  a:focus {
    text-decoration: underline;
    color: var(--main-gold);
  }

  li {
    list-style: square outside;
  }

  ul {
    padding-bottom: 0.5em;
    padding-top: 1em;
    padding-left: 3em;
    padding-right: 3em;
  }

  .desktop-limit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1em;
  }

  .jobs-description-title {
    @include futura-pt-bold;
    font-size: 1.4em;
    margin-bottom: 0.5em;
    color: #ffffff;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

#assocps-jobdescription {
  background-color: var(--main-menu-bgcolor);
}

#asstps-jobdescription {
  background-color: var(--main-gray);
}

@media (min-width: 768px) {
  .jobs-head,
  .jobs-description {
    padding-left: 15vw;
    padding-right: 15vw;

    .desktop-limit {
      display: flex;
      flex-wrap: wrap;
      justify-content: left middle;
      padding: 0 5vw 0;
    }

    .jobs-head-title {
      // max-width: 50vw;
      line-height: 1em;
      font-size: 4em;
      margin-bottom: 0.1em;
    }

    .jobs-head-text {
      max-width: 70vw;
      padding: 1em 0 1em;
      font-size: 1.4em;
    }

    .jobs-description-title {
      max-width: 60vw;
      font-size: 2.25em;
    }

    .jobs-description-text {
      max-width: 70vw;
    }
  }
}
