// Colour Definitions
:root {
    --main-blue: #4c7e97;
    --main-mediumblue: #5DA5C9;
    --main-lightblue: #7ad3ff;
    --main-gray: #3f3f3f;
    --main-lightgray: #d4d2d2;
    --main-darkgray: #707070;
    --main-gold: #e3c35b;
    --main-lightgold: #ffdc68;
    --main-rose: #966553;
    --main-orange: #e3a088;
    --main-menu-bgcolor: #383f43;
}