// Navbar Styles
.nav-bg-on {
    background: var(--main-menu-bgcolor);
}

.header {
    position: fixed;
    top: 0;
    z-index: 1000;
    color: #ffffff;
    width: 100%;
    @include futura-pt-medium();
    transition: background 250ms ease-in;

    /* 

    Mobile Styling

    */
    &:hover {
        background: var(--main-menu-bgcolor)
    }

    .nav-logo {
        display: block;
        float: left;
        margin: 0.75em 15px;
    }

    .header-logo {
        height: 3em;
    }

    .menu-toggle {
        display: block;
        float: right;
        padding: 1.5em 0.7em;
    }

    .menu-toggle:hover,
    .menu-toggle:focus {
        background: var(--main-lightblue);
    }

    .nav-bar .menu-close {
        position: absolute;
        right: 0;
        top: 0;
        padding: 1.5em 0.7em;
    }

    .fa-bars {
        @include svg-icon("../img/svg/bars.svg", 2em, 2em, var(--main-lightgray));
    }

    .fa-close {
        @include svg-icon("../img/svg/times.svg", 2em, 2em, var(--main-lightgray));
    }

    .nav-bar {
        position: absolute;
        display: none;
        left: -200px;
        top: 0;
        height: 100%;
        overflow-y: hidden;
        overflow-x: visible;
        z-index: 999;
    }

    .nav-bar ul {
        list-style: none;
        margin: 0;
        padding: 5em 0 0;
        min-height: 100%;
        width: 100%;
        background: var(--main-menu-bgcolor);
    }

    .nav-item a {
        display: block;
        padding: 0.8em 25px;
        text-align: center;
        line-height: 1em;
        font-size: 1.5em;
        color: #fff;
        text-decoration: none;
        border-bottom: 1px solid var(--main-lightgray);
    }

    .nav-bar li:first-child a {
        border-top: 1px solid var(--main-lightgray);
    }

    .nav-bar a:hover,
    .nav-bar a:focus {
        background: var(--main-lightblue);
        text-decoration: underline;
    }

    .nav-bar:target {
        display: block;
        left: 0;
        outline: none;
        width: 100%;
    }

    .nav-bar:target .menu-close {
        z-index: 1001;
    }

    .nav-bar:target ul {
        position: relative;
        z-index: 1000;
    }

    @supports (position: fixed) {

        .nav-bar,
        .nav-bar:target+.backdrop {
            position: fixed;
        }
    }

    /* 

    Full Desktop Sized

    */
    @media (min-width: 768px) {
        padding-left: 5vw;
        padding-right: 5vw;
        height: 5em;

        .menu-toggle,
        .nav-bar .menu-close {
            display: none;
        }

        .nav-bar {
            position: relative;
            left: auto;
            right: 0;
            top: auto;
            width: auto;
            max-width: 60vw;
            height: 100%;
            display: block;
            overflow-y: hidden;
            overflow-x: hidden;
            float: right;
        }

        .nav-bar ul {
            display: flex;
            padding: 0;
            height: 100%;
            width: auto;
            background: none;
        }

        .nav-bar li {
            padding-left: 0.5em;
        }

        .nav-bar a,
        .nav-bar li:first-child a {
            border: 0;
            font-size: 0.9em;
            height: auto;
            margin-top: 1.5em;
            padding: 0.5em 1.0em;
        }

        .nav-bar a:hover,
        .nav-bar a:focus {
            background: none;
            text-decoration: none;
            color: var(--main-gold);
        }

        .nav-bar li:last-child a {
            background-color: #497992;
            border-radius: 25px;
            transition: background 500ms ease;
        }

        .nav-bar li:last-child a:hover,
        .nav-bar li:last-child a:focus {
            background-color: var(--main-orange);
            color: #ffffff;
        }
    }
}