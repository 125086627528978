:root {
  --main-blue: #4c7e97;
  --main-mediumblue: #5da5c9;
  --main-lightblue: #7ad3ff;
  --main-gray: #3f3f3f;
  --main-lightgray: #d4d2d2;
  --main-darkgray: #707070;
  --main-gold: #e3c35b;
  --main-lightgold: #ffdc68;
  --main-rose: #966553;
  --main-orange: #e3a088;
  --main-menu-bgcolor: #383f43;
}

.nav-bg-on {
  background: var(--main-menu-bgcolor);
}

.header {
  z-index: 1000;
  color: #fff;
  width: 100%;
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 500;
  transition: background .25s ease-in;
  position: fixed;
  top: 0;
}

.header:hover {
  background: var(--main-menu-bgcolor);
}

.header .nav-logo {
  float: left;
  margin: .75em 15px;
  display: block;
}

.header .header-logo {
  height: 3em;
}

.header .menu-toggle {
  float: right;
  padding: 1.5em .7em;
  display: block;
}

.header .menu-toggle:hover, .header .menu-toggle:focus {
  background: var(--main-lightblue);
}

.header .nav-bar .menu-close {
  padding: 1.5em .7em;
  position: absolute;
  top: 0;
  right: 0;
}

.header .fa-bars {
  background-color: var(--main-lightgray);
  background-size: cover;
  width: 2em;
  height: 2em;
  mask-image: url("bars.15390f68.svg");
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

.header .fa-close {
  background-color: var(--main-lightgray);
  background-size: cover;
  width: 2em;
  height: 2em;
  mask-image: url("times.cf670588.svg");
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

.header .nav-bar {
  z-index: 999;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: -200px;
  overflow: visible hidden;
}

.header .nav-bar ul {
  background: var(--main-menu-bgcolor);
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 5em 0 0;
  list-style: none;
}

.header .nav-item a {
  text-align: center;
  color: #fff;
  border-bottom: 1px solid var(--main-lightgray);
  padding: .8em 25px;
  font-size: 1.5em;
  line-height: 1em;
  text-decoration: none;
  display: block;
}

.header .nav-bar li:first-child a {
  border-top: 1px solid var(--main-lightgray);
}

.header .nav-bar a:hover, .header .nav-bar a:focus {
  background: var(--main-lightblue);
  text-decoration: underline;
}

.header .nav-bar:target {
  outline: none;
  width: 100%;
  display: block;
  left: 0;
}

.header .nav-bar:target .menu-close {
  z-index: 1001;
}

.header .nav-bar:target ul {
  z-index: 1000;
  position: relative;
}

@supports (position: fixed) {
  .header .nav-bar, .header .nav-bar:target + .backdrop {
    position: fixed;
  }
}

@media (width >= 768px) {
  .header {
    height: 5em;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .header .menu-toggle, .header .nav-bar .menu-close {
    display: none;
  }

  .header .nav-bar {
    float: right;
    width: auto;
    max-width: 60vw;
    height: 100%;
    display: block;
    position: relative;
    top: auto;
    left: auto;
    right: 0;
    overflow: hidden;
  }

  .header .nav-bar ul {
    background: none;
    width: auto;
    height: 100%;
    padding: 0;
    display: flex;
  }

  .header .nav-bar li {
    padding-left: .5em;
  }

  .header .nav-bar a, .header .nav-bar li:first-child a {
    border: 0;
    height: auto;
    margin-top: 1.5em;
    padding: .5em 1em;
    font-size: .9em;
  }

  .header .nav-bar a:hover, .header .nav-bar a:focus {
    color: var(--main-gold);
    background: none;
    text-decoration: none;
  }

  .header .nav-bar li:last-child a {
    background-color: #497992;
    border-radius: 25px;
    transition: background .5s;
  }

  .header .nav-bar li:last-child a:hover, .header .nav-bar li:last-child a:focus {
    background-color: var(--main-orange);
    color: #fff;
  }
}

.foot {
  color: #fff;
  text-align: center;
  background: #000;
  padding: 1em 1em 1.5em;
}

.foot .foot-logo {
  height: 7em;
  padding: .2em 0 .3em;
}

.foot .foot-copyright {
  font-family: futura-pt, sans-serif;
  font-size: .7em;
  font-style: normal;
  font-weight: 400;
}

.foot .foot-title {
  color: var(--main-gold);
  margin-top: 1.5em;
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.foot .foot-menu li {
  padding: .25em .5em;
  display: inline-block;
}

.foot .foot-menu a, .foot .foot-contact, .foot .foot-contact a, .foot .foot-address {
  color: var(--main-lightgray);
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 300;
  text-decoration: none;
}

.foot .foot-menu a:hover, .foot .foot-menu a:focus, .foot .foot-contact a:hover, .foot .foot-contact a:focus {
  color: var(--main-lightgray);
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 300;
  text-decoration: underline;
}

@media (width >= 768px) {
  .foot {
    text-align: left;
    width: 100%;
    padding: 2em 5vw;
    font-size: .8em;
  }

  .foot .foot-logo-section {
    width: 20vw;
    margin-left: 2em;
    margin-right: 2em;
    display: inline-block;
  }

  .foot .foot-logo {
    height: 10em;
    padding: .2em 0 .3em;
  }

  .foot .foot-copyright {
    margin-top: 2em;
    margin-bottom: .5em;
    font-family: futura-pt, sans-serif;
    font-style: normal;
    font-weight: 300;
  }

  .foot .foot-left-elements {
    float: right;
    max-width: 60vw;
    padding-right: 2em;
    display: flex;
  }

  .foot .foot-bar, .foot .foot-geninquiry, .foot .foot-third {
    float: left;
    max-width: 25vw;
    margin-bottom: 2em;
    margin-left: 2em;
    margin-right: .5em;
    display: block;
  }

  .foot .foot-title {
    margin-top: .2em;
    margin-bottom: 1em;
    font-size: 1em;
  }

  .foot .foot-menu li {
    padding: 0;
    display: block;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 400;
}

html {
  background: var(--main-menu-bgcolor);
  color: var(--main-lightgray);
  scroll-behavior: smooth;
  font-size: 16pt;
  overflow-x: hidden;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

section.home-main {
  background-image: image-set("GalaxyContours.e4cf6280.webp" 1x type("image/webp"), "GalaxyContours.a0047e46.png" 1x type("image/png"));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding: 1.5em 1.5em 2em;
}

section.home-main .main-title {
  color: #fff;
  margin-top: 20vh;
  font-family: futura-pt, sans-serif;
  font-size: 3em;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  display: block;
}

section.home-main .main-subtitle {
  color: var(--main-gold);
  margin-top: 5vh;
  font-family: futura-pt, sans-serif;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 300;
  display: block;
}

section.home-services {
  background-color: var(--main-blue);
  color: #fff;
  z-index: 10;
  background-image: image-set("BoxBackground.98ba9433.webp" 1x type("image/webp"), "BoxBackground.82ae763d.png" 1x type("image/png"));
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  min-height: 100vh;
  padding: 1.5em 2em;
  position: relative;
}

section.home-services .service-option {
  padding-top: 1em;
  padding-bottom: .5em;
}

section.home-services .service-icon {
  margin: .5em 0 .7em;
}

section.home-services .service-subtitle {
  float: left;
  margin-bottom: .2em;
  padding-top: .1em;
  font-family: futura-pt, sans-serif;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 500;
}

section.home-services .service-text {
  color: var(--main-lightgray);
  margin-top: .5em;
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 300;
  display: inline-block;
}

#lightbulb {
  background-color: var(--main-lightblue);
  background-size: cover;
  width: 3em;
  height: 3em;
  mask-image: url("lightbulb.d63fcb5d.svg");
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

#users {
  background-color: var(--main-lightblue);
  background-size: cover;
  width: 3em;
  height: 3em;
  mask-image: url("users.7918f32f.svg");
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

#cogs {
  background-color: var(--main-lightblue);
  background-size: cover;
  width: 3em;
  height: 3em;
  mask-image: url("cogs.2e99ccde.svg");
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

#hands-helping {
  background-color: var(--main-lightblue);
  background-size: cover;
  width: 3em;
  height: 3em;
  mask-image: url("hands-helping.d02475bc.svg");
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

#chalkboard-teacher {
  background-color: var(--main-lightblue);
  background-size: cover;
  width: 3em;
  height: 3em;
  mask-image: url("chalkboard-teacher.3a60237b.svg");
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

#comments {
  background-color: var(--main-lightblue);
  background-size: cover;
  width: 3em;
  height: 3em;
  mask-image: url("comments.b044d880.svg");
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

section.home-research {
  z-index: 10;
  color: #fff;
  background-color: #7f5643;
  min-height: 100vh;
  padding: 1.5em 2em;
  position: relative;
}

section.home-research .section-research-image {
  background-image: image-set("Pendulum.123b72d8.webp" 1x type("image/webp"), "Pendulum.a56e27df.jpg" 1x type("image/jpg"));
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  min-height: 40vw;
  margin-top: -1.5em;
  margin-left: -2em;
}

section.home-research .section-title {
  padding-top: .5em;
}

section.home-research .section-text {
  color: var(--main-gold);
  font-family: futura-pt, sans-serif;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 300;
}

.section-link {
  padding-top: 1.5em;
  padding-bottom: 2em;
}

.section-link a {
  color: #fff;
  font-family: futura-pt, sans-serif;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 300;
  text-decoration: underline;
}

.section-title {
  padding-top: 1em;
  padding-bottom: .5em;
  font-size: 2em;
  line-height: 120%;
}

@media (width >= 768px) {
  section {
    width: 100%;
  }

  section.home-main .home-holder {
    z-index: 10;
    position: absolute;
    top: 12vh;
    left: 5em;
  }

  section.home-main .main-title {
    width: 70vw;
    max-width: 70%;
    font-size: 3.4em;
  }

  section.home-main .main-subtitle {
    width: 50vw;
    max-width: 70%;
    margin-top: 1em;
    font-size: 1.2em;
  }

  section.home-services {
    text-align: justify;
    padding-left: 10vw;
    padding-right: 10vw;
  }

  section.home-services .section-title {
    text-align: left;
    margin-top: 10vh;
    font-family: futura-pt, sans-serif;
    font-style: normal;
    font-weight: 500;
  }

  section.home-services .service-option-holder {
    width: 100%;
  }

  section.home-services .service-option {
    text-align: left;
    vertical-align: top;
    width: 28%;
    height: 8em;
    padding-left: 2em;
    display: inline-block;
  }

  section.home-services .service-icon {
    float: left;
    margin-left: -2.7em;
  }

  section.home-services .service-right {
    float: left;
    width: 100%;
    margin-left: .5em;
  }

  section.home-services .service-subtitle {
    font-size: .9em;
  }

  section.home-services .service-text {
    margin-top: 0;
    font-family: futura-pt, sans-serif;
    font-size: .8em;
    font-style: normal;
    font-weight: 300;
  }

  section.home-services .stretch {
    width: 100%;
    font-size: 0;
    line-height: 0;
    display: inline-block;
  }

  section.home-services #lightbulb {
    background-color: var(--main-lightblue);
    background-size: cover;
    width: 2.5em;
    height: 2.5em;
    mask-image: url("lightbulb.d63fcb5d.svg");
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
  }

  section.home-services #users {
    background-color: var(--main-lightblue);
    background-size: cover;
    width: 2.5em;
    height: 2.5em;
    mask-image: url("users.7918f32f.svg");
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
  }

  section.home-services #cogs {
    background-color: var(--main-lightblue);
    background-size: cover;
    width: 2.5em;
    height: 2.5em;
    mask-image: url("cogs.2e99ccde.svg");
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
  }

  section.home-services #hands-helping {
    background-color: var(--main-lightblue);
    background-size: cover;
    width: 2.5em;
    height: 2.5em;
    mask-image: url("hands-helping.d02475bc.svg");
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
  }

  section.home-services #chalkboard-teacher {
    background-color: var(--main-lightblue);
    background-size: cover;
    width: 2.5em;
    height: 2.5em;
    mask-image: url("chalkboard-teacher.3a60237b.svg");
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
  }

  section.home-services #comments {
    background-color: var(--main-lightblue);
    background-size: cover;
    width: 2.5em;
    height: 2.5em;
    mask-image: url("comments.b044d880.svg");
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
  }

  section.home-research {
    display: flex;
  }

  section.home-research .section-research-image {
    min-height: unset;
    background-position-x: right;
    width: 50vw;
    height: 20em;
    margin: auto -2em;
  }

  section.home-research .home-research-right {
    width: 30vw;
    margin: auto;
    font-size: .9em;
  }

  section.home-research .home-research-right .section-title {
    font-family: futura-pt, sans-serif;
    font-size: 2em;
    font-style: normal;
    font-weight: 500;
  }

  section.home-research .home-research-right .section-link {
    text-align: left;
  }

  div.section-link {
    text-align: right;
  }

  .desktop-limit {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
}

.contact-main {
  color: #fff;
  background-image: image-set("MainDuotone.53dda083.webp" 1x type("image/webp"), "MainDuotone.f436a55c.jpg" 1x type("image/jpg"));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 90vh;
  padding: 5em 1.5em;
  display: flex;
}

.contact-main .contact-left {
  margin: auto;
  padding: .7em 0;
}

.contact-main .contact-left .contact-title {
  padding: .5em 0 .2em;
  font-family: futura-pt-bold, sans-serif;
  font-size: 3em;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
}

.contact-main .contact-left .contact-text {
  padding: .5em 0;
  font-family: futura-pt, sans-serif;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 300;
}

.contact-main .contact-right {
  margin: auto;
  padding: .7em 0;
}

.contact-main .contact-right bolder {
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 500;
}

.contact-main .contact-right .contact-companyname {
  padding-bottom: .2em;
  font-family: futura-pt, sans-serif;
  font-size: 1.3em;
  font-style: normal;
  font-weight: 500;
}

.contact-main .contact-right .contact-email {
  padding-bottom: 1em;
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.contact-main .contact-right .contact-email a {
  color: #fff;
  text-decoration: none;
}

.contact-main .contact-right .contact-mailing {
  padding-bottom: 1.6em;
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.contact-main .contact-right .contact-socials {
  flex-direction: row;
  display: flex;
}

.contact-main .contact-right .social-link {
  margin-right: 1.5em;
}

.contact-main .contact-right a:hover, .contact-main .contact-right a:focus {
  color: var(--main-gold);
  text-decoration: underline;
}

.contact-main .contact-right a:hover #linkedin, .contact-main .contact-right a:hover #twitter, .contact-main .contact-right a:hover #facebook, .contact-main .contact-right a:hover #github, .contact-main .contact-right a:focus #linkedin, .contact-main .contact-right a:focus #twitter, .contact-main .contact-right a:focus #facebook, .contact-main .contact-right a:focus #github {
  background-color: var(--main-gold);
}

.contact-main #linkedin {
  background-color: #fff;
  background-size: cover;
  width: 2em;
  height: 2em;
  mask-image: url("linkedin.81c8027d.svg");
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

.contact-main #twitter {
  background-color: #fff;
  background-size: cover;
  width: 2em;
  height: 2em;
  mask-image: url("twitter.bce56408.svg");
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

.contact-main #facebook {
  background-color: #fff;
  background-size: cover;
  width: 2em;
  height: 2em;
  mask-image: url("facebook.e3e4493c.svg");
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

.contact-main #github {
  background-color: #fff;
  background-size: cover;
  width: 2em;
  height: 2em;
  mask-image: url("github.fc494809.svg");
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

@media (width >= 768px) {
  .contact-main {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .contact-main .desktop-limit {
    display: flex;
  }

  .contact-main .contact-left, .contact-main .contact-right {
    max-width: 35vw;
    margin: auto 2em;
  }

  .contact-main .contact-left .contact-title {
    font-size: 3.5em;
  }

  .contact-main .contact-right .contact-companyname {
    margin-top: 2em;
  }
}

.e404-main {
  background-color: var(--main-blue);
  color: #fff;
  background-image: image-set("BoxBackground.98ba9433.webp" 1x type("image/webp"), "BoxBackground.82ae763d.png" 1x type("image/png"));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 90vh;
  padding: 5em 1.5em;
  display: flex;
}

.e404-main .e404-left {
  margin: auto;
  padding: .7em 0;
}

.e404-main .e404-left .e404-title {
  text-align: center;
  padding: .4em 0 .1em;
  font-family: futura-pt-bold, sans-serif;
  font-size: 5em;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
}

.e404-main .e404-left .e404-title .text-404 {
  font-family: futura-pt-bold, sans-serif;
  font-size: 1.3em;
  font-style: normal;
  font-weight: 700;
}

.e404-main .e404-right {
  margin: auto;
  padding: .7em 0;
}

.e404-main .e404-right .e404-text {
  text-align: center;
  padding: .5em 0 3em;
  font-family: futura-pt, sans-serif;
  font-size: 1.4em;
  font-style: normal;
  font-weight: 500;
}

.e404-main .e404-right .e404-text .highlight {
  color: var(--main-gold);
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 500;
}

.e404-main .e404-right .e404-links {
  flex-direction: row;
  display: flex;
}

.e404-main .e404-right a.linkbutton {
  color: #fff;
  background-color: var(--main-mediumblue);
  border-radius: 1.5em;
  margin: auto;
  padding: .5em 1em;
  font-size: 1.5em;
  text-decoration: none;
}

.e404-main .e404-right a:hover, .e404-main .e404-right a:focus {
  background-color: var(--main-orange);
  text-decoration: none;
  transition: background .5s;
}

@media (width >= 768px) {
  .e404-main {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .e404-main .desktop-limit {
    display: flex;
  }

  .e404-main .e404-left, .e404-main .e404-right {
    margin: auto 2em;
  }

  .e404-main .e404-left {
    max-width: 30vw;
  }

  .e404-main .e404-left .e404-title {
    font-size: 6em;
    line-height: .8em;
  }

  .e404-main .e404-right .e404-text {
    text-align: left;
    margin-top: 2em;
    padding-bottom: 1.2em;
    font-size: 1.75em;
  }

  .e404-main .e404-right .e404-links {
    justify-content: center;
  }

  .e404-main .e404-right a.linkbutton {
    margin: auto 2em;
    padding: .3em 1em;
    font-size: 1.2em;
  }
}

.waw-head {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--main-rose);
  color: #fff;
  background-image: image-set("Trees.ab38ec10.webp" 1x type("image/webp"), "Trees.f95cf9d2.jpg" 1x type("image/jpg"));
  min-height: 100vh;
  padding: 5em 1.5em;
  display: flex;
}

.waw-head .desktop-limit {
  display: flex;
}

.waw-head .waw-top-text {
  background-color: #96655380;
  margin: auto;
  padding: 5vh 5vw;
  font-family: futura-pt, sans-serif;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 500;
}

.waw-story {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--main-rose);
  min-height: 90vh;
  padding: 5em 1.5em;
  display: flex;
}

.waw-story .waw-story-title {
  color: var(--main-gold);
  padding-bottom: .5em;
  font-family: futura-pt-bold, sans-serif;
  font-size: 1.7em;
  font-style: normal;
  font-weight: 700;
}

.waw-story p {
  margin-bottom: 1em;
}

.waw-story .waw-story-pullquote {
  text-align: center;
  border-top: 5px solid var(--main-gold);
  border-bottom: 5px solid var(--main-gold);
  margin: 2em auto 0;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  font-size: 1.7em;
}

.waw-founder {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--main-blue);
  background-image: image-set("BlueFibers.e0567e99.webp" 1x type("image/webp"), "BlueFibers.4e8cddb4.jpg" 1x type("image/jpg"));
  min-height: 90vh;
  padding: 5em 1.5em;
  display: flex;
}

.waw-founder .waw-founder-heading {
  font-family: futura-pt, sans-serif;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 500;
}

.waw-founder .waw-founder-name {
  font-family: futura-pt-bold, sans-serif;
  font-size: 1.7em;
  font-style: normal;
  font-weight: 700;
}

.waw-founder .waw-founder-title {
  margin-bottom: 1em;
  font-family: futura-pt, sans-serif;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 300;
}

.waw-founder p {
  margin-bottom: 1em;
}

.waw-founder .waw-founder-socials {
  flex-direction: row;
  justify-content: center;
  margin-top: 1em;
  display: flex;
}

.waw-founder .social-icons {
  margin-right: 1.5em;
}

.waw-founder .waw-founder-image {
  background-image: image-set("New_Mubdi.dedec429.webp" 1x type("image/webp"), "New_Mubdi.e8fbb2c9.jpg" 1x type("image/jpg"));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  width: 60vw;
  height: 60vw;
  margin: 0 auto 1em;
}

.waw-founder a:hover, .waw-founder a:focus {
  color: var(--main-gold);
  text-decoration: underline;
}

.waw-founder a:hover #linkedin, .waw-founder a:hover #twitter, .waw-founder a:hover #web, .waw-founder a:hover #github, .waw-founder a:focus #linkedin, .waw-founder a:focus #twitter, .waw-founder a:focus #web, .waw-founder a:focus #github {
  background-color: var(--main-gold);
}

.waw-founder #linkedin {
  background-color: #fff;
  background-size: cover;
  width: 2em;
  height: 2em;
  mask-image: url("linkedin.81c8027d.svg");
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

.waw-founder #twitter {
  background-color: #fff;
  background-size: cover;
  width: 2em;
  height: 2em;
  mask-image: url("twitter.bce56408.svg");
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

.waw-founder #web {
  background-color: #fff;
  background-size: cover;
  width: 2em;
  height: 2em;
  mask-image: url("globe.57536a18.svg");
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

.waw-founder #github {
  background-color: #fff;
  background-size: cover;
  width: 2em;
  height: 2em;
  mask-image: url("github.fc494809.svg");
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

.waw-team {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--main-gray);
  background-image: image-set("WavyLines.30bfd680.webp" 1x type("image/webp"), "WavyLines.fe6916d3.jpg" 1x type("image/jpg"));
  min-height: 90vh;
  padding: 5em 1.5em;
  display: flex;
}

.waw-team .waw-team-title {
  color: var(--main-lightgray);
  text-align: center;
  padding-bottom: .5em;
  font-family: futura-pt-bold, sans-serif;
  font-size: 1.7em;
  font-style: normal;
  font-weight: 700;
}

.waw-team .team-holder {
  flex-flow: wrap;
  place-content: space-between center;
  display: flex;
}

.waw-team .team-member-holder {
  width: 33%;
  min-width: 15em;
  height: 15em;
  position: relative;
}

.waw-team .team-member-hover {
  z-index: 10;
  backdrop-filter: grayscale() blur(5px);
  opacity: 0;
  background-color: #01010199;
  width: 100%;
  height: 100%;
  padding: 1em 1.5em 5em;
  font-family: futura-pt, sans-serif;
  font-size: .9em;
  font-style: normal;
  font-weight: 300;
  transition: opacity .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.waw-team .team-member-hover .qualifications {
  text-transform: uppercase;
  color: var(--main-gold);
  margin-bottom: 1em;
  font-size: .7em;
}

.waw-team .team-member-frame {
  z-index: 5;
  color: #fff;
  background-color: var(--main-gray);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: .5em 1em 1em;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.waw-team .team-member-info {
  z-index: 15;
  color: #fff;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: .5em 1em 1em;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.waw-team .team-member-info .team-member-position {
  color: var(--main-gold);
  text-transform: uppercase;
  margin-top: auto;
  font-family: futura-pt, sans-serif;
  font-size: .7em;
  font-style: normal;
  font-weight: 400;
}

.waw-team .team-member-info .team-member-name {
  font-family: futura-pt-bold, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.waw-team .team-member-info:hover ~ .team-member-hover {
  opacity: 1;
}

.waw-team div#team-mubdi {
  background-image: linear-gradient(#0000, #0000 50%, #000000b3), image-set("mubdi.15f56e7e.webp" 1x type("image/webp"), "mubdi.b66da3f5.jpg" 1x type("image/jpg"));
}

.waw-team div#team-jen {
  background-image: linear-gradient(#0000, #0000 50%, #000000b3), image-set("jen.51abb673.webp" 1x type("image/webp"), "jen.02e88214.jpg" 1x type("image/jpg"));
}

.waw-team div#team-hansen {
  background-image: linear-gradient(#0000, #0000 50%, #000000b3), image-set("hansen.019b494c.webp" 1x type("image/webp"), "hansen.2e174457.jpg" 1x type("image/jpg"));
}

.waw-team div#team-tai {
  background-image: linear-gradient(#0000, #0000 50%, #000000b3), image-set("tai.3e364751.webp" 1x type("image/webp"), "tai.dea95a2d.jpg" 1x type("image/jpg"));
}

.waw-opportunities {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--main-blue);
  color: #fff;
  background-image: image-set("BlueArcs.1b9e9efa.webp" 1x type("image/webp"), "BlueArcs.79f7ebdb.jpg" 1x type("image/jpg"));
  min-height: 90vh;
  padding: 5em 1.5em;
  display: flex;
}

.waw-opportunities .waw-opportunities-pretitle {
  text-transform: uppercase;
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-family: futura-pt-bold, sans-serif;
  font-size: .7em;
  font-style: normal;
  font-weight: 700;
}

.waw-opportunities .waw-opportunities-title {
  margin-bottom: .75em;
  font-family: futura-pt-bold, sans-serif;
  font-size: 1.6em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
}

.waw-opportunities p {
  margin-top: 1em;
}

.waw-opportunities .waw-opportunities-button-container {
  display: flex;
}

.waw-opportunities .waw-opportunities-button {
  text-align: center;
  color: #fff;
  background-color: var(--main-rose);
  margin: 2em auto;
  padding: .75em;
  text-decoration: none;
  transition: background .5s;
  display: inline-block;
}

.waw-opportunities .waw-opportunities-button:hover {
  background-color: var(--main-menu-bgcolor);
}

.waw-name {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--main-rose);
  background-image: image-set("MainDuotone.53dda083.webp" 1x type("image/webp"), "MainDuotone.f436a55c.jpg" 1x type("image/jpg"));
  padding: 5em 1.5em;
  display: flex;
}

.waw-name .waw-name-title {
  margin-bottom: .5em;
  font-family: futura-pt-bold, sans-serif;
  font-size: 1.7em;
  font-style: normal;
  font-weight: 700;
}

.waw-name .waw-name-title i {
  font-family: futura-pt-bold, sans-serif;
  font-style: italic;
  font-weight: 700;
}

.waw-name i {
  font-family: futura-pt, sans-serif;
  font-style: italic;
  font-weight: 400;
}

.waw-name p {
  margin-bottom: 1em;
}

.waw-name .waw-name-picture {
  background-image: image-set("taqi-al-din.c219409f.webp" 1x type("image/webp"), "taqi-al-din.33992b3b.jpg" 1x type("image/jpg"));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 75vw;
  min-height: 40vh;
  margin: auto;
}

@media (width >= 768px) {
  .waw-head, .waw-story, .waw-founder, .waw-team, .waw-opportunities, .waw-name {
    padding-left: 3vw;
    padding-right: 3vw;
  }

  .waw-head .desktop-limit, .waw-story .desktop-limit, .waw-founder .desktop-limit, .waw-team .desktop-limit, .waw-opportunities .desktop-limit, .waw-name .desktop-limit {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    display: flex;
  }

  .waw-head .waw-top-text, .waw-story .waw-top-text, .waw-founder .waw-top-text, .waw-team .waw-top-text, .waw-opportunities .waw-top-text, .waw-name .waw-top-text {
    max-width: 20em;
    margin: auto;
    padding: 1.5em 1em;
    font-family: futura-pt, sans-serif;
    font-size: 2em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25em;
  }

  .waw-head .waw-story-text, .waw-story .waw-story-text, .waw-founder .waw-story-text, .waw-team .waw-story-text, .waw-opportunities .waw-story-text, .waw-name .waw-story-text {
    flex-direction: column;
    justify-content: center;
    max-width: 30em;
    padding: 3em 3em 1em;
    display: flex;
  }

  .waw-head .waw-story-text .waw-story-title, .waw-story .waw-story-text .waw-story-title, .waw-founder .waw-story-text .waw-story-title, .waw-team .waw-story-text .waw-story-title, .waw-opportunities .waw-story-text .waw-story-title, .waw-name .waw-story-text .waw-story-title {
    font-size: 2.5em;
  }

  .waw-head .waw-story-pullquote, .waw-story .waw-story-pullquote, .waw-founder .waw-story-pullquote, .waw-team .waw-story-pullquote, .waw-opportunities .waw-story-pullquote, .waw-name .waw-story-pullquote {
    border-top-width: 2px;
    border-bottom-width: 2px;
    max-width: 10em;
    margin: auto;
    padding-top: .7em;
    padding-bottom: .7em;
    font-size: 1.7em;
  }

  .waw-head .waw-founder-image, .waw-story .waw-founder-image, .waw-founder .waw-founder-image, .waw-team .waw-founder-image, .waw-opportunities .waw-founder-image, .waw-name .waw-founder-image {
    width: 35vw;
    max-width: 25em;
    height: 35vw;
    max-height: 25em;
    margin: 0 1em auto -5em;
    position: relative;
    overflow: hidden;
  }

  .waw-head .waw-founder-text, .waw-story .waw-founder-text, .waw-founder .waw-founder-text, .waw-team .waw-founder-text, .waw-opportunities .waw-founder-text, .waw-name .waw-founder-text {
    flex-direction: column;
    justify-content: center;
    width: 45vw;
    max-width: 50%;
    padding-left: 2em;
    font-size: .9em;
    display: flex;
  }

  .waw-head .waw-founder-text .waw-founder-heading, .waw-story .waw-founder-text .waw-founder-heading, .waw-founder .waw-founder-text .waw-founder-heading, .waw-team .waw-founder-text .waw-founder-heading, .waw-opportunities .waw-founder-text .waw-founder-heading, .waw-name .waw-founder-text .waw-founder-heading {
    margin-bottom: -.2em;
    font-size: 1.5em;
  }

  .waw-head .waw-founder-text .waw-founder-name, .waw-story .waw-founder-text .waw-founder-name, .waw-founder .waw-founder-text .waw-founder-name, .waw-team .waw-founder-text .waw-founder-name, .waw-opportunities .waw-founder-text .waw-founder-name, .waw-name .waw-founder-text .waw-founder-name {
    color: #fff;
    font-size: 2.5em;
  }

  .waw-head .waw-team-text, .waw-story .waw-team-text, .waw-founder .waw-team-text, .waw-team .waw-team-text, .waw-opportunities .waw-team-text, .waw-name .waw-team-text {
    flex-direction: column;
    justify-content: center;
    padding: 3em 3em 1em;
    display: flex;
  }

  .waw-head .waw-team-text .waw-team-title, .waw-story .waw-team-text .waw-team-title, .waw-founder .waw-team-text .waw-team-title, .waw-team .waw-team-text .waw-team-title, .waw-opportunities .waw-team-text .waw-team-title, .waw-name .waw-team-text .waw-team-title {
    font-size: 2.5em;
  }

  .waw-head .waw-opportunities-text, .waw-story .waw-opportunities-text, .waw-founder .waw-opportunities-text, .waw-team .waw-opportunities-text, .waw-opportunities .waw-opportunities-text, .waw-name .waw-opportunities-text {
    align-items: center;
    display: flex;
  }

  .waw-head .waw-opportunities-pretitle, .waw-story .waw-opportunities-pretitle, .waw-founder .waw-opportunities-pretitle, .waw-team .waw-opportunities-pretitle, .waw-opportunities .waw-opportunities-pretitle, .waw-name .waw-opportunities-pretitle {
    margin-bottom: .2em;
    font-size: 1.2em;
  }

  .waw-head .waw-opportunities-title, .waw-story .waw-opportunities-title, .waw-founder .waw-opportunities-title, .waw-team .waw-opportunities-title, .waw-opportunities .waw-opportunities-title, .waw-name .waw-opportunities-title {
    margin-bottom: .75em;
    font-size: 4em;
    line-height: 1.1;
  }

  .waw-head .waw-opportunities-body, .waw-story .waw-opportunities-body, .waw-founder .waw-opportunities-body, .waw-team .waw-opportunities-body, .waw-opportunities .waw-opportunities-body, .waw-name .waw-opportunities-body {
    max-width: 50vw;
    margin-left: auto;
    margin-right: auto;
  }

  .waw-head .waw-name-description, .waw-story .waw-name-description, .waw-founder .waw-name-description, .waw-team .waw-name-description, .waw-opportunities .waw-name-description, .waw-name .waw-name-description {
    flex-direction: column;
    justify-content: center;
    width: 60%;
    max-width: 25em;
    display: flex;
  }

  .waw-head .waw-name-description .waw-name-title, .waw-story .waw-name-description .waw-name-title, .waw-founder .waw-name-description .waw-name-title, .waw-team .waw-name-description .waw-name-title, .waw-opportunities .waw-name-description .waw-name-title, .waw-name .waw-name-description .waw-name-title {
    font-size: 2em;
    line-height: 1.1em;
  }

  .waw-head .waw-name-description .waw-name-body, .waw-story .waw-name-description .waw-name-body, .waw-founder .waw-name-description .waw-name-body, .waw-team .waw-name-description .waw-name-body, .waw-opportunities .waw-name-description .waw-name-body, .waw-name .waw-name-description .waw-name-body {
    font-size: .9em;
  }

  .waw-head .waw-name-picture, .waw-story .waw-name-picture, .waw-founder .waw-name-picture, .waw-team .waw-name-picture, .waw-opportunities .waw-name-picture, .waw-name .waw-name-picture {
    width: 35vw;
    max-width: 40%;
    margin: -5em auto;
  }
}

.research-head {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--main-rose);
  color: #fff;
  background-image: image-set("nightsky.ea36a632.webp" 1x type("image/webp"), "nightsky.ed619497.jpg" 1x type("image/jpg"));
  min-height: 100vh;
  padding: 5em 1.5em;
  display: flex;
}

.research-head .desktop-limit {
  display: flex;
}

.research-head .research-top-text {
  background-color: #4c7e9780;
  margin: auto;
  padding: 5vh 5vw;
  font-family: futura-pt, sans-serif;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 500;
}

.research-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  width: 80vw;
  height: 80vw;
  margin: 2em auto;
}

.research-description-title {
  margin-bottom: .5em;
  font-family: futura-pt-bold, sans-serif;
  font-size: 2em;
  font-style: normal;
  font-weight: 700;
}

.research-stars {
  background-image: image-set("MainDuotone.53dda083.webp" 1x type("image/webp"), "MainDuotone.f436a55c.jpg" 1x type("image/jpg"));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 90vh;
  padding: 5em 1.5em;
  display: flex;
}

.research-stars .research-image {
  background-image: image-set("Dragonfish_Psuedocolour_small.b9d9638d.webp" 1x type("image/webp"), "Dragonfish_Psuedocolour_small.4c3f06ab.jpg" 1x type("image/jpg"));
}

.research-radiotransients {
  background-image: image-set("BlueArcs.1b9e9efa.webp" 1x type("image/webp"), "BlueArcs.79f7ebdb.jpg" 1x type("image/jpg"));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 90vh;
  padding: 5em 1.5em;
  display: flex;
}

.research-radiotransients .research-image {
  background-image: image-set("ARO.c24f2545.webp" 1x type("image/webp"), "ARO.6df611dc.jpg" 1x type("image/jpg"));
}

.research-visualization {
  background-image: image-set("GreyDottedPattern.2abd34c0.webp" 1x type("image/webp"), "GreyDottedPattern.f44ebd18.jpg" 1x type("image/jpg"));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 90vh;
  padding: 5em 1.5em;
  display: flex;
}

.research-visualization .research-image {
  background-image: image-set("frbvis.ff416373.webp" 1x type("image/webp"), "frbvis.547404fb.jpg" 1x type("image/jpg"));
}

@media (width >= 768px) {
  .research-head, .research-stars, .research-radiotransients, .research-visualization {
    padding-left: 3vw;
    padding-right: 3vw;
  }

  .research-head .desktop-limit, .research-stars .desktop-limit, .research-radiotransients .desktop-limit, .research-visualization .desktop-limit {
    flex-flow: wrap;
    justify-content: center;
    display: flex;
  }

  .research-head .research-top-text, .research-stars .research-top-text, .research-radiotransients .research-top-text, .research-visualization .research-top-text {
    max-width: 20em;
    margin: auto;
    padding: 1.5em 1em;
    font-family: futura-pt, sans-serif;
    font-size: 2em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25em;
  }

  .research-head .research-text, .research-stars .research-text, .research-radiotransients .research-text, .research-visualization .research-text {
    max-width: 20em;
    margin: auto 1.5em;
  }

  .research-head .research-image, .research-stars .research-image, .research-radiotransients .research-image, .research-visualization .research-image {
    width: 35vw;
    max-width: 20em;
    height: 35vw;
    max-height: 20em;
    margin: auto;
    position: relative;
    overflow: hidden;
  }
}

.service-head {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--main-blue);
  color: #fff;
  background-image: image-set("BlueFibers.e0567e99.webp" 1x type("image/webp"), "BlueFibers.4e8cddb4.jpg" 1x type("image/jpg"));
  min-height: 100vh;
  padding: 5em 1.5em;
  display: flex;
}

.service-head .desktop-limit {
  flex-direction: column;
  justify-content: center;
  margin-top: 1em;
  display: flex;
}

.service-head .service-head-title {
  margin-bottom: .5em;
  font-family: futura-pt-bold, sans-serif;
  font-size: 2em;
  font-style: normal;
  font-weight: 700;
}

.service-description {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding: 5em 1.5em;
  display: flex;
}

.service-description p {
  padding-bottom: .5em;
}

.service-description dt {
  color: #fff;
  padding-top: .5em;
  padding-left: 0;
  padding-right: 0;
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.service-description dd {
  padding: .25em 0 1.25em;
}

.service-description h3 {
  color: #fff;
  padding: .75em 0 .25em;
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.service-description a {
  color: #fff;
  text-decoration: none;
}

.service-description a:hover, .service-description a:focus {
  color: var(--main-gold);
  text-decoration: underline;
}

.service-description li {
  list-style: square inside;
}

.service-description ul {
  padding-bottom: 1em;
}

.service-description .desktop-limit {
  flex-direction: column;
  justify-content: center;
  margin-top: 1em;
  display: flex;
}

.service-description .service-description-title {
  color: #fff;
  margin-bottom: .5em;
  font-family: futura-pt-bold, sans-serif;
  font-size: 1.4em;
  font-style: normal;
  font-weight: 700;
}

section#technology-integration {
  background-color: var(--main-rose);
  background-image: image-set("MainDuotone.53dda083.webp" 1x type("image/webp"), "MainDuotone.f436a55c.jpg" 1x type("image/jpg"));
}

section#dataflow-management {
  background-color: var(--main-blue);
  background-image: image-set("BoxBackground.98ba9433.webp" 1x type("image/webp"), "BoxBackground.82ae763d.png" 1x type("image/png"));
}

section#visualization {
  background-image: image-set("WavyLines.30bfd680.webp" 1x type("image/webp"), "WavyLines.fe6916d3.jpg" 1x type("image/jpg"));
}

section#project-management {
  background-color: var(--main-blue);
  background-image: image-set("BlueArcs.1b9e9efa.webp" 1x type("image/webp"), "BlueArcs.79f7ebdb.jpg" 1x type("image/jpg"));
}

section#training {
  background-image: image-set("GreyDottedPattern.2abd34c0.webp" 1x type("image/webp"), "GreyDottedPattern.f44ebd18.jpg" 1x type("image/jpg"));
}

section#industry {
  background-color: var(--main-rose);
}

section#outreach {
  background-color: var(--main-blue);
  background-image: image-set("DottedPattern.f384fd45.webp" 1x type("image/webp"), "DottedPattern.cb2edf27.jpg" 1x type("image/jpg"));
}

@media (width >= 768px) {
  .service-head, .service-description {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .service-head .desktop-limit, .service-description .desktop-limit {
    justify-content: left middle;
    flex-wrap: wrap;
    padding: 0 5vw;
    display: flex;
  }

  .service-head .service-head-title, .service-description .service-head-title {
    max-width: 50vw;
    margin-bottom: .1em;
    font-size: 4em;
    line-height: 1em;
  }

  .service-head .service-head-text, .service-description .service-head-text {
    max-width: 70vw;
    padding: 1em 0;
    font-size: 1.4em;
  }

  .service-head .service-description-title, .service-description .service-description-title {
    max-width: 60vw;
    font-size: 2.25em;
  }

  .service-head .service-description-text, .service-description .service-description-text {
    max-width: 70vw;
  }
}

.jobs-head {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--main-blue);
  color: #fff;
  background-image: image-set("BlueFibers.e0567e99.webp" 1x type("image/webp"), "BlueFibers.4e8cddb4.jpg" 1x type("image/jpg"));
  min-height: 100vh;
  padding: 5em 1.5em;
  display: flex;
}

.jobs-head .desktop-limit {
  flex-direction: column;
  justify-content: center;
  margin-top: 1em;
  display: flex;
}

.jobs-head .jobs-head-title {
  text-align: center;
  margin-bottom: .5em;
  font-family: futura-pt-bold, sans-serif;
  font-size: 2em;
  font-style: normal;
  font-weight: 700;
}

.jobs-head .joblist {
  justify-content: center;
  margin-top: 1em;
  margin-left: 2em;
  margin-right: 2em;
  font-size: .9em;
  display: flex;
}

.jobs-head .jobentry {
  text-align: center;
  margin: .75em;
}

.jobs-head .jobentry .joblink {
  color: #fff;
  font-family: futura-pt-bold, sans-serif;
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
  transition: color .5s;
}

.jobs-head .jobentry .joblink:hover, .jobs-head .jobentry .joblink:focus {
  color: var(--main-orange);
}

.jobs-description {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding: 5em 1.5em;
  font-size: 1em;
  line-height: 1.5;
  display: flex;
}

.jobs-description p {
  padding-top: 1em;
  padding-bottom: .5em;
}

.jobs-description b {
  font-family: futura-pt-bold, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.jobs-description h3 {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  padding: 1.75em 0 0;
  font-family: futura-pt, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.jobs-description .highlight {
  color: var(--main-orange);
  font-size: 1.5em;
}

.jobs-description hr {
  border-color: var(--main-lightgray);
  margin-top: 2.5em;
  margin-bottom: 2.5em;
}

.jobs-description a {
  color: var(--main-orange);
  text-decoration: none;
}

.jobs-description a:hover, .jobs-description a:focus {
  color: var(--main-gold);
  text-decoration: underline;
}

.jobs-description li {
  list-style: square;
}

.jobs-description ul {
  padding: 1em 3em .5em;
}

.jobs-description .desktop-limit {
  flex-direction: column;
  justify-content: center;
  margin-top: 1em;
  display: flex;
}

.jobs-description .jobs-description-title {
  color: #fff;
  text-align: center;
  margin-bottom: .5em;
  margin-left: auto;
  margin-right: auto;
  font-family: futura-pt-bold, sans-serif;
  font-size: 1.4em;
  font-style: normal;
  font-weight: 700;
}

#assocps-jobdescription {
  background-color: var(--main-menu-bgcolor);
}

#asstps-jobdescription {
  background-color: var(--main-gray);
}

@media (width >= 768px) {
  .jobs-head, .jobs-description {
    padding-left: 15vw;
    padding-right: 15vw;
  }

  .jobs-head .desktop-limit, .jobs-description .desktop-limit {
    justify-content: left middle;
    flex-wrap: wrap;
    padding: 0 5vw;
    display: flex;
  }

  .jobs-head .jobs-head-title, .jobs-description .jobs-head-title {
    margin-bottom: .1em;
    font-size: 4em;
    line-height: 1em;
  }

  .jobs-head .jobs-head-text, .jobs-description .jobs-head-text {
    max-width: 70vw;
    padding: 1em 0;
    font-size: 1.4em;
  }

  .jobs-head .jobs-description-title, .jobs-description .jobs-description-title {
    max-width: 60vw;
    font-size: 2.25em;
  }

  .jobs-head .jobs-description-text, .jobs-description .jobs-description-text {
    max-width: 70vw;
  }
}
/*# sourceMappingURL=404.2004557e.css.map */
